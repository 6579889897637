import './services.css'
import InfoLeft from './info-left';

function Services() {

    return (

        <div className="services__container">

            < InfoLeft />
      
        </div>

    );
  }
  
  export default Services;