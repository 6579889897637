
function Infobar () {

    return (
        <div className='careers__infobar'>
            <p className='careers__info-text'>*Not Currently Hiring*</p>
        </div>
    );
}

export default Infobar;